import styled from 'styled-components'

import { BulletPointIcon } from '../../../mycercle/components/account-card-header/BulletPoint'
import { PageContainer } from '../../../shared/components/page-container'
import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import {
  BodyMediumBold,
  BodySmallBold,
} from '../../../shared/components/typography'

export const StWrapper = styled.div`
  position: relative;
  /* Reset fixed positioning */
  transform: scale(1);

  width: 100%;
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
`

export const StClubLogo = styled(StoryblokServiceImage)`
  width: 26px;
  height: 28px;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    width: 48px;
    height: 52px;
  }
`

export const StContainer = styled(PageContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;

  position: relative;
  overflow-x: scroll;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    overflow-x: auto;
    padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
    min-height: 0px;
  }
`

export const StTimeLineGradient = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 5;
  pointer-events: none;

  background: linear-gradient(
    to right,
    ${({ theme }) => theme.Colors.Neutrals.Darkest} 20%,
    rgba(0, 0, 0, 0) 40%
  );

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    display: none;
  }
`

export const StTimeLineColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }

  ${StClubLogo} {
    width: 48px;
    height: 52px;
  }

  &:first-of-type {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
    position: sticky;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 100;

    background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
    margin-right: ${({ theme }) => theme.UI.SpacingPx.Space2};
  }
`

export const StTimeLinePart = styled.div<{ $extraTime?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;

  min-width: ${({ $extraTime }) => ($extraTime ? '150px' : '450px')};
  flex-grow: ${({ $extraTime }) => ($extraTime ? 1 : 3)};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    min-width: 0;
  }
`

export const StTimeLinePartBackground = styled.div`
  display: grid;
  align-items: center;
  flex-grow: 1;

  height: 2px;

  background-color: ${({ theme }) => theme.Colors.Neutrals.Light};
`

export const StTimeLinePartForeground = styled.div<{ $fill: number }>`
  height: ${({ theme }) => theme.UI.SpacingPx.Space1};

  position: absolute;
  top: -1px;
  left: 0;
  right: ${({ $fill }) => 100 - $fill}%;

  background-color: ${({ theme }) => theme.Colors.Primary.Base};
`

export const StTimeLineEventContainer = styled.div<{ $position: number }>`
  position: absolute;
  top: 50%;
  left: ${({ $position }) => $position}%;
  transform: translateX(-50%) translateY(-50%);

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  align-items: center;

  z-index: 5;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  }
`

export const StEventIcon = styled(BulletPointIcon)<{ $flip: boolean }>`
  transform: ${({ $flip }) => ($flip ? 'rotate(180deg)' : 'rotate(0deg)')};
`

export const StEventIcons = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
  min-height: 26px;

  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
  border: 2px solid ${({ theme }) => theme.Colors.Neutrals.Darkest};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

export const StEventTooltip = styled.div`
  display: none;
  flex-direction: column;

  grid-template-columns: min-content 1fr;
  column-gap: ${({ theme }) => theme.UI.SpacingPx.Space2};

  align-items: center;

  background-color: white;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space3};
  border-radius: ${({ theme }) => theme.UI.SpacingPx.Space2};
  color: black;

  z-index: 10;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  & p {
    // For some reason medium regular from theme is 500 font weight
    font-weight: 400 !important;
  }

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    display: grid;
  }
`

export const StMinuteLabel = styled(BodySmallBold)`
  color: ${({ theme }) => theme.Colors.Neutrals.Light};
  text-align: center;
  min-height: 18px;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    display: none;
  }
`

export const StSpacer = styled.div`
  height: ${({ theme }) => theme.UI.SpacingPx.Space2};
  grid-column: span 2;
`

export const StTooltipIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

export const StTooltipIcon = styled.div`
  display: grid;
  place-items: center;

  justify-self: center;
`

export const StTimeLineLabel = styled(BodyMediumBold)`
  color: ${({ theme }) => theme.Colors.Neutrals.Light};
  min-width: 60px;

  text-align: center;
`
