import {
  StoryblokComponent,
  storyblokEditable,
  type ISbStoryData,
} from '@storyblok/react'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

import { PageHeader } from '../../../content/components/page-header'
import { MatchLiveCard } from '../../../matches/components/match-live-card'
import { MatchLiveUpdates } from '../../../matches/components/match-live-updates'
import { MatchTimeLine } from '../../../matches/components/match-time-line/MatchTimeLine'
import { useLiveMatch } from '../../../matches/hooks/useLiveMatch'
import { PageContainer } from '../../../shared/components/page-container'
import { Tabs } from '../../../shared/components/tabs'
import { useStoryblokTranslationsContext } from '../../context/translations-provider'
import { useTranslations } from '../../hooks/useTranslations'

import { StArticleTitle } from './Match.styled'

import type { SbMatchData } from './Match.types'

enum TabEnum {
  MATCH_REPORT = 'match-report',
  MATCH_LIVE = 'match-live',
}

interface Props {
  blok: SbMatchData
  locale: string
  relations: ISbStoryData<unknown>[]
}

export const Match = ({ blok, locale, relations }: Props) => {
  // -- State --
  const [currentTab, setCurrentTab] = useState<TabEnum>(TabEnum.MATCH_REPORT)

  // -- Hooks --
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)
  const { matchEvents, matchTiming, matchIsLive, penaltyShootoutGoals } =
    useLiveMatch(blok)
  const { query } = useRouter()

  // -- Vars --
  const tabs = [
    {
      label: t('match_report'),
      key: TabEnum.MATCH_REPORT,
      content: (
        <>
          <StArticleTitle>{blok.report2?.content?.title}</StArticleTitle>
          {blok.report2?.content?.children.map((item) => (
            <StoryblokComponent
              key={item._uid}
              blok={item}
              locale={locale ?? 'nl'}
              relations={relations}
              translations={translations}
            />
          ))}
        </>
      ),
    },
    {
      label: t('match_live'),
      key: TabEnum.MATCH_LIVE,
      content: (
        <MatchLiveUpdates
          blok={blok}
          locale={locale}
          relations={relations}
          hasReport
        />
      ),
    },
  ]

  // -- Methods --
  const setInitialTab = useCallback(() => {
    if (query.tab) {
      setCurrentTab(
        query.tab === 'live' ? TabEnum.MATCH_LIVE : TabEnum.MATCH_REPORT
      )
    }
  }, [query.tab])

  // -- Effects --
  useEffect(() => {
    setInitialTab()
  }, [query.tab, setInitialTab])

  // -- Render --
  return (
    <div {...storyblokEditable(blok)}>
      <PageHeader
        title={blok.home_team.name + ' - ' + blok.away_team.name}
        subtitle={t('match_live_subtitle')}
        marginBottom={false}
      />

      <MatchLiveCard
        homeTeamName={blok.home_team.name}
        homeTeamScore={
          blok.home_team_goals === '' ? undefined : blok.home_team_goals
        }
        homeTeamLogo={blok.home_team.content?.logo}
        awayTeamName={blok.away_team.name}
        awayTeamScore={
          blok.away_team_goals === '' ? undefined : blok.away_team_goals
        }
        awayTeamLogo={blok.away_team.content?.logo}
        matchDate={blok.matchday}
        matchTime={blok.time}
        live={matchIsLive}
        goals={[...matchEvents.values()].flatMap((events) =>
          events.filter((event) => event.type === 'goal')
        )}
        locale={locale}
        showPenaltyShootout={!!blok.penalty_shootout_start}
        penaltyShootoutGoals={penaltyShootoutGoals}
      />
      <MatchTimeLine
        homeTeamLogo={blok.home_team.content?.logo}
        awayTeamLogo={blok.away_team.content?.logo}
        events={matchEvents}
        matchProgressTime={matchTiming?.minute ?? 0}
        showExtraTime={
          !!blok.extra_time_first_half || !!blok.extra_time_second_half
        }
      />

      <PageContainer>
        {blok.report2?.id ? (
          <Tabs<TabEnum>
            tabs={tabs}
            currentTab={currentTab}
            onChange={(tab) => setCurrentTab(tab)}
          />
        ) : (
          <MatchLiveUpdates blok={blok} locale={locale} relations={relations} />
        )}
      </PageContainer>
    </div>
  )
}
