import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFutbol } from '@fortawesome/sharp-solid-svg-icons/faFutbol'
import { format, isAfter, isBefore, isSameDay } from 'date-fns'
import { enUS, fr, nlBE } from 'date-fns/locale'
import { fromZonedTime } from 'date-fns-tz'
import { useMemo } from 'react'

import { ButtonLink } from '../../../shared/components/button'
import { BodyMediumRegular } from '../../../shared/components/typography'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { setTimeOnDate } from '../../utils/setTimeOnDate'
import { MatchClub } from '../match-club'

import {
  StContainer,
  StTitle,
  StTitleContainer,
  StFullCardContainer,
  StCercleCirlce,
  StResultContainer,
  StMatchday,
  StTitleContent,
  StButtonContainer,
  StSponsorImage,
  StSponsorLink,
  StLiveContainer,
  StLiveBlink,
  StLiveTitle,
  StLiveScore,
  StGoalsContainer,
  StGoal,
  StMatchLiveCardColumn,
  StLiveScoreSup,
} from './MatchLiveCard.styled'

import type { MatchEvent } from '../match-time-line/MatchTimeLine'
import type { Hyperlink, StoryblokImage } from 'types'

interface MatchLiveCardProps {
  className?: string
  title?: string
  homeTeamName: string
  homeTeamLogo?: StoryblokImage
  homeTeamScore?: string
  awayTeamName: string
  awayTeamLogo?: StoryblokImage
  awayTeamScore?: string
  matchDate: string
  matchTime?: string
  sponsorImage?: StoryblokImage
  sponsorLink?: Hyperlink
  ticketLink?: Hyperlink
  infoLink?: Hyperlink
  vipLink?: Hyperlink
  daysShorthand?: string
  locale?: string
  live?: 'live' | 'full-time' | 'penalty-shootout'
  goals?: MatchEvent[]
  showPenaltyShootout?: boolean
  penaltyShootoutGoals?: {
    home: number
    away: number
  }
  liveUpdates?: string
}

export const isValidLink = (link: Hyperlink | string) => {
  if (typeof link === 'string') {
    return (
      link &&
      link !== '' &&
      link !== '/en/' &&
      link !== '/nl' &&
      link !== '/fr/' &&
      link !== '#'
    )
  }
  return (
    link &&
    link.href !== '' &&
    link.href !== '/en/' &&
    link.href !== '/nl' &&
    link.href !== '/fr/' &&
    link.href !== '#'
  )
}

export const MatchLiveCard = ({
  className,
  homeTeamName,
  homeTeamLogo,
  homeTeamScore = '0',
  awayTeamName,
  awayTeamLogo,
  awayTeamScore = '0',
  matchDate,
  matchTime,
  sponsorImage,
  sponsorLink,
  ticketLink,
  infoLink,
  vipLink,
  live = 'live',
  locale,
  goals,
  showPenaltyShootout,
  penaltyShootoutGoals,
  liveUpdates,
}: MatchLiveCardProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  const homeGoals = goals?.filter((goal) => goal.team === 'home') ?? []
  const awayGoals = goals?.filter((goal) => goal.team === 'away') ?? []

  const startedAtLabel = useMemo(() => {
    const dateFnsLocale = locale === 'nl' ? nlBE : locale === 'fr' ? fr : enUS
    let kickoff = new Date(matchDate)

    if (matchTime) {
      kickoff = setTimeOnDate(kickoff, matchTime)
    }

    if (isBefore(new Date(), fromZonedTime(kickoff, 'UTC'))) {
      return `${format(fromZonedTime(kickoff, 'UTC'), 'EEEE dd MMMM', {
        locale: dateFnsLocale,
      })}, ${t('match_card_starts_at').toLowerCase()} ${
        matchTime && matchTime !== ''
          ? matchTime
          : format(fromZonedTime(kickoff, 'UTC'), 'HH:mm')
      }`
    }

    if (isSameDay(new Date(), fromZonedTime(kickoff, 'UTC'))) {
      return `${t('match_card_started_at')} ${
        matchTime && matchTime !== ''
          ? matchTime
          : format(fromZonedTime(kickoff, 'UTC'), 'HH:mm')
      }`
    }

    return `${format(fromZonedTime(kickoff, 'UTC'), 'EEEE dd MMMM', {
      locale: dateFnsLocale,
    })}, ${t('match_card_started_at').toLowerCase()} ${
      matchTime && matchTime !== ''
        ? matchTime
        : format(fromZonedTime(kickoff, 'UTC'), 'HH:mm')
    }`
  }, [locale, matchDate, matchTime, t])

  return (
    <StContainer className={className}>
      <StTitleContainer>
        <StTitleContent $mainTitle>
          <StTitle>{startedAtLabel}</StTitle>
        </StTitleContent>
        <StTitleContent>
          {sponsorImage &&
            (sponsorLink && isValidLink(sponsorLink) ? (
              <StSponsorLink href={sponsorLink.href}>
                <StSponsorImage image={sponsorImage} width={150} height={36} />
              </StSponsorLink>
            ) : (
              <StSponsorImage image={sponsorImage} width={150} height={36} />
            ))}
        </StTitleContent>
      </StTitleContainer>

      <StFullCardContainer>
        <StResultContainer>
          <StMatchLiveCardColumn>
            <MatchClub
              variant={'light'}
              name={homeTeamName}
              logo={homeTeamLogo}
            />
            {homeGoals.length > 0 && (
              <StGoalsContainer>
                {homeGoals.reverse().map((goal, index) => (
                  <StGoal key={index}>
                    <FontAwesomeIcon icon={faFutbol} fontSize={12} />
                    <BodyMediumRegular>{goal.text}</BodyMediumRegular>
                  </StGoal>
                ))}
              </StGoalsContainer>
            )}
          </StMatchLiveCardColumn>

          <StMatchday>
            {isAfter(new Date(), fromZonedTime(new Date(matchDate), 'UTC')) &&
              (live === 'live' || live === 'penalty-shootout' ? (
                <StLiveContainer>
                  <StLiveBlink></StLiveBlink>
                  <StLiveTitle>
                    {live === 'penalty-shootout'
                      ? t('match_card_penalties')
                      : t('match_card_live')}
                  </StLiveTitle>
                </StLiveContainer>
              ) : (
                <StLiveContainer $grey>
                  <StLiveTitle>{'Full time'}</StLiveTitle>
                </StLiveContainer>
              ))}
            <StLiveScore>
              {homeTeamScore}
              {showPenaltyShootout && (
                <StLiveScoreSup>
                  &nbsp;({penaltyShootoutGoals?.home ?? 0})
                </StLiveScoreSup>
              )}{' '}
              - {awayTeamScore}
              {showPenaltyShootout && (
                <StLiveScoreSup>
                  &nbsp;({penaltyShootoutGoals?.away ?? 0})
                </StLiveScoreSup>
              )}
            </StLiveScore>
          </StMatchday>
          <StMatchLiveCardColumn>
            <MatchClub
              variant={'light'}
              name={awayTeamName}
              logo={awayTeamLogo}
            />
            {awayGoals.length > 0 && (
              <StGoalsContainer>
                {awayGoals.reverse().map((goal, index) => (
                  <StGoal key={index}>
                    <FontAwesomeIcon icon={faFutbol} fontSize={12} />
                    <BodyMediumRegular>{goal.text}</BodyMediumRegular>
                  </StGoal>
                ))}
              </StGoalsContainer>
            )}
          </StMatchLiveCardColumn>
        </StResultContainer>
        <StCercleCirlce variation={3} />
        <StButtonContainer>
          {ticketLink && isValidLink(ticketLink) && (
            <ButtonLink
              variant="primary"
              prefixIcon="ticket"
              href={ticketLink.href}
              size="small"
              gaEventLabel="press_live_match_tickets"
            >
              {t('match_card_tickets')}
            </ButtonLink>
          )}

          {liveUpdates && liveUpdates !== '' && (
            <ButtonLink
              variant="secondary"
              href={liveUpdates}
              size="small"
              gaEventLabel="press_live_match_live"
            >
              {t('match_card_live')}
            </ButtonLink>
          )}
          {infoLink && isValidLink(infoLink) && (
            <ButtonLink
              variant="secondary"
              href={infoLink.href}
              size="small"
              gaEventLabel="press_live_match_info"
            >
              {t('match_card_info')}
            </ButtonLink>
          )}
          {vipLink && isValidLink(vipLink) && (
            <ButtonLink
              variant="secondary"
              href={vipLink.href}
              gaEventLabel="press_live_match_vip"
              size="small"
            >
              {t('match_card_vip')}
            </ButtonLink>
          )}
        </StButtonContainer>
      </StFullCardContainer>
    </StContainer>
  )
}
