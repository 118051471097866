import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  format,
  formatDistance,
  isAfter,
  isSameDay,
  isWithinInterval,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns'
import { enGB, fr, nl } from 'date-fns/locale'

import {
  BodyLargeRegular,
  ButtonLabel,
  Heading2,
} from '../../../shared/components/typography'
import { MatchLiveUpdate } from '../../../storyblok/components/match-live-update'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { useLiveMatch } from '../../hooks/useLiveMatch'

import {
  StLiveContainer,
  StLiveCounter,
  StLiveCounterLabel,
  StLiveHeader,
  StLiveIndicator,
  StLiveUpdates,
  StUpdateLabel,
} from './MatchLiveUpdates.styled'

import type { SbMatchData } from '../../../storyblok/components/match/Match.types'
import type { ISbStoryData } from '@storyblok/react'

interface Props {
  blok: SbMatchData
  locale: string
  relations: ISbStoryData<unknown>[]
  hasReport?: boolean
}

export const MatchLiveUpdates = ({
  blok,
  locale,
  relations,
  hasReport,
}: Props) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  const {
    matchTiming,
    matchIsLive,
    lastUpdateTime,
    matchLiveLabel,
    sortedUpdates,
  } = useLiveMatch(blok)

  const matchTimingLabel = matchTiming
    ? `${matchTiming.minute}${
        matchTiming.extraTime ? `+${matchTiming.extraTime}’` : '’'
      }`
    : null

  return (
    <StLiveContainer>
      {!hasReport && (
        <StLiveHeader>
          <Heading2>{t('match_live_updates')}</Heading2>
          {matchIsLive !== 'full-time' && (
            <StLiveCounter>
              <StLiveIndicator>
                <FontAwesomeIcon icon={faCircle} color="white" fontSize={12} />
              </StLiveIndicator>
              <ButtonLabel>
                <StLiveCounterLabel>{matchLiveLabel}</StLiveCounterLabel>
                {matchIsLive === 'live' && ` ${matchTimingLabel ?? ''}`}
              </ButtonLabel>
            </StLiveCounter>
          )}
          <StUpdateLabel>
            {lastUpdateTime && (
              <BodyLargeRegular>
                {t('match_live_last_update')}:&nbsp;
                {isSameDay(new Date(lastUpdateTime), new Date())
                  ? formatDistance(new Date(lastUpdateTime), new Date(), {
                      addSuffix: true,
                      locale:
                        locale === 'nl' ? nl : locale === 'fr' ? fr : enGB,
                    })
                  : format(new Date(lastUpdateTime), 'dd/MM/yyyy HH:mm')}
              </BodyLargeRegular>
            )}
          </StUpdateLabel>
        </StLiveHeader>
      )}
      <StLiveUpdates>
        {sortedUpdates.map((liveUpdate) => (
          <MatchLiveUpdate
            key={liveUpdate._uid}
            blok={liveUpdate}
            locale={locale}
            relations={relations}
            halfTime={
              blok.second_half
                ? !liveUpdate.minute &&
                  isWithinInterval(new Date(liveUpdate.created_at), {
                    start: new Date(blok.half_time),
                    end: new Date(blok.second_half),
                  })
                : blok.half_time
                ? !liveUpdate.minute &&
                  isAfter(
                    new Date(liveUpdate.created_at),
                    new Date(blok.half_time)
                  )
                : false
            }
          />
        ))}
      </StLiveUpdates>
    </StLiveContainer>
  )
}
