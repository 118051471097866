import { useCallback, useEffect, useState } from 'react'

import { useUser } from '../../authentication/hooks/useUser'
import { useAxios } from '../../shared/hooks/useAxios'

import type { MyCercleCard } from '../types/myCercleCards'

export const useCards = () => {
  const { user } = useUser()
  const axios = useAxios()

  const [cards, setCards] = useState<MyCercleCard[]>([])
  const [loading, setLoading] = useState(false)

  const fetchBalance = useCallback(
    async (cardNumber: string) => {
      if (!axios) return 0
      try {
        const { data: balanceData } = await axios.get(
          `${
            process.env.NEXT_PUBLIC_BACKEND_URL || ''
          }/api/sjef/${cardNumber}/balance`
        )
        return balanceData?.balance ?? 0
      } catch {
        return 0
      }
    },
    [axios]
  )

  const fetchCards = useCallback(async () => {
    if (!axios) return
    setLoading(true)
    const { data: cards } = await axios.get(
      `${process.env.NEXT_PUBLIC_BACKEND_URL || ''}/api/users/${user?.id}/cards`
    )
    await Promise.all(
      cards.map(async (card: MyCercleCard) => {
        card.balance = await fetchBalance(card.number)
      })
    )
    setCards(cards)
    setLoading(false)
  }, [axios, user?.id, fetchBalance])

  const deleteCard = (number: string) => {
    if (!axios) return
    axios.delete(
      `${process.env.NEXT_PUBLIC_BACKEND_URL || ''}/api/users/${
        user?.id
      }/cards/${number}`
    )
    setCards(cards.filter((card) => card.number !== number))
  }

  useEffect(() => {
    fetchCards()
  }, [fetchCards])

  return { cards, loading, fetchCards, fetchBalance, deleteCard }
}
