import styled from 'styled-components'

export const StLiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space10};

  max-width: 1080px;
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
  margin-bottom: 0;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    margin-top: 40px;
    margin-bottom: 160px;
  }
`

export const StLiveHeader = styled.div`
  display: grid;
  grid-template-columns: auto min-content;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    margin-top: 80px;
  }
`

export const StUpdateLabel = styled.div`
  grid-column: span 2;
`

export const StLiveCounter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};

  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space2};
  background-color: ${({ theme }) => theme.Colors.Components.Toaster.ErrorFill};
  height: ${({ theme }) => theme.UI.SpacingPx.Space8};

  white-space: nowrap;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    height: ${({ theme }) => theme.UI.SpacingPx.Space12};
    gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  }
`

export const StLiveCounterLabel = styled.span`
  display: none;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    display: inline-block;
  }
`

export const StLiveIndicator = styled.div`
  display: grid;
  place-items: center;

  width: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

export const StLiveUpdates = styled.div`
  display: flex;
  flex-direction: column;
`
